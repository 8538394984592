
export default {
    data: function () {
        return {
            filterFormModel: {
                client_id: null
            },
            clients: []
        }
    },
    methods: {
        setTab(client_id) {
            this.filterFormModel.client_id = client_id;
            this.load();
        },
    },
    mounted: function () {
        this.listLoading = true;
        this.$service.get(`${this.service}/overview`, {params: this.filterFormModel}).then(response => {
            if (response.data) {
                this.clients = response.data.clients;
                if (!this.filterFormModel.client_id) {
                    this.filterFormModel.client_id = this.clients[0].id;
                }
                this.load();
            }
        }).finally(() => {
            this.listLoading = false;
        });
    }
};