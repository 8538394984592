<template>
    <div class="dropdown">
        <button :class="btnClass" type="button" data-toggle="dropdown">
            <!--<i v-if="icon" class="icon-left" class=""></i>--> 
            <i class="icon-left fa fa-calendar-alt"></i>
            <span v-if="label"> {{label}}: </span>
            {{selected}}
        </button>
        <div class="dropdown-menu is-custom" :class="rightMenu?'dropdown-menu-right':null">
            <div class="dropdown-menu__inner">
                <ul class="ul-list">
                    <li v-for="item in options" :key="item.value" class="li-check" :class="inputVal===item.value?'active':null">
                        <a href="javascript:;" @click="select(item.value)">{{item.text}}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        props: ['model', 'attribute', 'label', 'type', 'rightMenu', 'icon'],
        data() {
            return {inputVal: this.model[this.attribute] ? this.model[this.attribute] : null, options: [{"value":"0","text":"Tất cả quý"}]}
        },
        computed: {
            btnClass() {
                if (this.type === 'bordered') {
                    return "btn btn-outline-gray btn-sm is-border-1 dropdown-toggle";
                }
                return "btn dropdown-toggle btn-sm";
            },
            selected() {
                let selected = this.options.find(i => i.value === this.inputVal);
                if (selected) {
                    return selected.text;
                }
                return null;
            }
        },
        watch: {
            model: {
                handler(val) {
                    this.inputVal = val[this.attribute];
                }, deep: true
            },
        },
        methods: {
            select(value) {
                this.inputVal = value;
                this.model[this.attribute] = value;
                this.$emit('change');
            }
        },
        mounted: function () {
            let currentYear = moment().format("YYYY");
            for (let year = 2020; year <= currentYear; year++) {
                for (let q = 1; q < 5; q++) {
                    this.options.push({value: q + '-' + year, text: "Quý " + q + "/" + year});
                }
            }
        }
    }
</script>

<style>
    .mg-selected {margin-top:50px}
</style>